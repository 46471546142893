import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../Utils/Axios";
import { BASE_URL } from "../../Utils/Urls";
export const PlanListServices = createAsyncThunk(
    "PlanListServices",
    async () => {
      try {
        let url = `${BASE_URL}/subscription-plan/subscription-plan-list`;
        const res = await axios.get(url);
        console.log(res);
        return res.data;
      } catch (error) {
        throw error;
      }
    }
  );