import { createSlice } from "@reduxjs/toolkit";
import { bookStudentSlot, userBookingSlot } from "../Thunk/userBookingSlot";
import { errorTost, sucessTost } from "../../Utils/Helper";
import { cancelSessionServices, classFeedBackServices, classReportServics, endClassServices, endRecordingServices, joinClassServices, joinClassTimeServices, myClassListServices, sessionNotesServices, startRecordingServices } from "../Thunk/mySession";

const initialState = {
  data: null,
  loading: false,
  error: null,
  myClassList: [],
  joinSessionData: null,
  sucess:false,
  sessionReport:null,
  pageCount:0,
  sessionDetails:null

};

const mySessionSlice = createSlice({
  name: "MY_SESSION_SLICE",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(myClassListServices.pending, (state) => {
      return { ...state, loading: true,myClassList:[] };
    });
    builder.addCase(myClassListServices.fulfilled, (state, action) => {
      //   console.log(action.payload,'sksksksks');
      return { ...state, myClassList: action?.payload?.result, loading: false,pageCount:action?.payload?.result?.totalCount };
    });
    builder.addCase(myClassListServices.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });


    builder.addCase(joinClassTimeServices.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(joinClassTimeServices.fulfilled, (state, action) => {
      return { ...state, loading: false };
    });
    builder.addCase(joinClassTimeServices.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });



    builder.addCase(endClassServices.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(endClassServices.fulfilled, (state, action) => {
      return { ...state, loading: false };
    });
    builder.addCase(endClassServices.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });

    

    builder.addCase(classReportServics.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(classReportServics.fulfilled, (state, action) => {
      return { ...state,sessionReport:action?.payload?.result,loading: false };
    });
    builder.addCase(classReportServics.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });

    
    builder.addCase(classFeedBackServices.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(classFeedBackServices.fulfilled, (state, action) => {
      return { ...state,loading: false };
    });
    builder.addCase(classFeedBackServices.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });
    

    builder.addCase(startRecordingServices.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(startRecordingServices.fulfilled, (state, action) => {
      sucessTost('Recording Start Sucessfully')
      return { ...state,loading: false };
    });
    builder.addCase(startRecordingServices.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });

    builder.addCase(endRecordingServices.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(endRecordingServices.fulfilled, (state, action) => {
      sucessTost('Recording End ')
      return { ...state,loading: false };
    });
    builder.addCase(endRecordingServices.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });


    builder.addCase(cancelSessionServices.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(cancelSessionServices.fulfilled, (state, action) => {
      return { ...state,loading: false };
    });
    builder.addCase(cancelSessionServices.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });

    

    builder.addCase(sessionNotesServices.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(sessionNotesServices.fulfilled, (state, action) => {
      return { ...state,sessionDetails:action?.payload?.result,loading: false };
    });
    builder.addCase(sessionNotesServices.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });


    
  },

});

export default mySessionSlice.reducer;
