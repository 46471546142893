import { createSlice } from "@reduxjs/toolkit";
import {
  bookDemoSlotServices,
  bookStudentSlot,
  userBookingSlot,
} from "../Thunk/userBookingSlot";
import { errorTost, sucessTost } from "../../Utils/Helper";
import {
  countryListServices,
  planCouponServices,
  stateListServices,
} from "../Thunk/commonServices";

const initialState = {
  data: null,
  loading: false,
  error: null,
  countryList: [],
  stateList: [],
  couponList:[]
};

const CommonSlice = createSlice({
  name: "COMMON_SLICE",
  initialState,
  extraReducers: (builder) => {
    // Slot List
    builder.addCase(countryListServices.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(countryListServices.fulfilled, (state, action) => {
      return { ...state, countryList: action.payload.result, loading: false };
    });
    builder.addCase(countryListServices.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });

    builder.addCase(stateListServices.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(stateListServices.fulfilled, (state, action) => {
      return { ...state, stateList: action.payload.result, loading: false };
    });
    builder.addCase(stateListServices.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });

    builder.addCase(planCouponServices.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(planCouponServices.fulfilled, (state, action) => {
      return { ...state, couponList: action.payload.result, loading: false };
    });
    builder.addCase(planCouponServices.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });
  },
});

export default CommonSlice.reducer;
