import { createSlice } from "@reduxjs/toolkit";
import {
  bookDemoSlotServices,
  bookStudentSlot,
  userBookingSlot,
} from "../Thunk/userBookingSlot";
import { errorTost, sucessTost } from "../../Utils/Helper";
import {
  classOverViewDetailsServices,
  roadmapDetailsServices,
  userConditionServices,
  userFeedBackReportServices,
  userLevelServices,
} from "../Thunk/DashboardServices";

const initialState = {
  data: null,
  loading: false,
  error: null,
  roadMapList: [],
  userLevelData: null,
  classDetails: {},
  progressReportDetails: {},
  userConditions: {},
};

const dashboardSlice = createSlice({
  name: "DASHBOARD_SLICE",
  initialState,
  extraReducers: (builder) => {
    // Slot List
    builder.addCase(roadmapDetailsServices.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(roadmapDetailsServices.fulfilled, (state, action) => {
      return { ...state, roadMapList: action.payload.result, loading: false };
    });
    builder.addCase(roadmapDetailsServices.rejected, (state, action) => {
      return {
        ...state,
        loading: false,
        roadMapList: [],
        error: "Something went wrong",
      };
    });

    builder.addCase(userLevelServices.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(userLevelServices.fulfilled, (state, action) => {
      return { ...state, userLevelData: action.payload.result, loading: false };
    });
    builder.addCase(userLevelServices.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong",userLevelData:null };
    });

    builder.addCase(classOverViewDetailsServices.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(classOverViewDetailsServices.fulfilled, (state, action) => {
      return { ...state, classDetails: action.payload.result, loading: false };
    });
    builder.addCase(classOverViewDetailsServices.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong",classDetails:{}};
    });

    builder.addCase(userFeedBackReportServices.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(userFeedBackReportServices.fulfilled, (state, action) => {
      return {
        ...state,
        progressReportDetails: action.payload.result,
        loading: false,
      };
    });
    builder.addCase(userFeedBackReportServices.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong",progressReportDetails:{} };
    });

    builder.addCase(userConditionServices.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(userConditionServices.fulfilled, (state, action) => {
      return {
        ...state,
        userConditions: action.payload.result,
        loading: false,
      };
    });
    builder.addCase(userConditionServices.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" ,userConditions:{}};
    });
  },
});

export default dashboardSlice.reducer;
