import { createSlice } from "@reduxjs/toolkit";
import {
  
  getUserProfileServices,
  sendOtpEmailServices,
  userSubscriptionDetailsServices,
  userTransactionListServices,
  verifyEmailServices,
} from "../Thunk/profileServices";
import { editProfileServices } from "../../Services/RegistrationService";

const initialState = {
  data: null,
  loading: false,
  error: null,
  planList: [],
  userSubscription: [],
  transactionHistory: [],
  sucess: false,
  userProfileDetails:{}
};

const userProfileSlice = createSlice({
  name: "PROFILE_SLICE",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(userSubscriptionDetailsServices.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(
      userSubscriptionDetailsServices.fulfilled,
      (state, action) => {
        return {
          ...state,
          userSubscription: action.payload.result,
          loading: false,
        };
      }
    );
    builder.addCase(
      userSubscriptionDetailsServices.rejected,
      (state, action) => {
        return { ...state, loading: false, error: "Something went wrong" };
      }
    );

    builder.addCase(userTransactionListServices.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(userTransactionListServices.fulfilled, (state, action) => {
      return {
        ...state,
        transactionHistory: action.payload.result,
        loading: false,
      };
    });
    builder.addCase(userTransactionListServices.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });

    builder.addCase(editProfileServices.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(editProfileServices.fulfilled, (state, action) => {
      return { ...state, loading: false };
    });
    builder.addCase(editProfileServices.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });

    builder.addCase(getUserProfileServices.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(getUserProfileServices.fulfilled, (state, action) => {
      return {
        ...state,
        userProfileDetails: action.payload.result,
        loading: false,
      };
    });
    builder.addCase(getUserProfileServices.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });

    

    builder.addCase(verifyEmailServices.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(verifyEmailServices.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
      };
    });
    builder.addCase(verifyEmailServices.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });

    

    builder.addCase(sendOtpEmailServices.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(sendOtpEmailServices.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
      };
    });
    builder.addCase(sendOtpEmailServices.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });

    

    
  },
});

export default userProfileSlice.reducer;
