import axios from "axios";
import { getStorageValue } from "../Services/LocalStorageServices";
import { handleError } from "../Services/ErrorHandlerServices";
import store from "../Redux/Store";
import { emitter } from "./Emmiter";
import { IS_AUTH } from "../Redux/Types";

axios.interceptors.request.use(
  (config) => {
    const token = getStorageValue("accessToken");
    config.headers["Authorization"] = `Bearer ${token}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.data?.message == "jwt expired") {
      localStorage.clear();
      store.dispatch({
        type: IS_AUTH,
        payload: true,
      });
      emitter.emit("logout");
      window.location.href = "/";
    }
    // handleError(error)
    return Promise.reject(error);
  }
);

export default axios;
