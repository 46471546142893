import { createSlice } from "@reduxjs/toolkit";
import { PlanListServices } from "../Thunk/planServices";

const initialState = {
  data: null,
  loading: false,
  error: null,
  planList: [],
  sucess:false
};

const planSlice = createSlice({
  name: "PLAN_SLICE",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PlanListServices.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(PlanListServices.fulfilled, (state, action) => {
      let temp = action.payload.result.sort((a,b)=>a.planType-b.planType);
      console.log(temp);
      return { ...state, planList:temp, loading: false };
    });
    builder.addCase(PlanListServices.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });

  },
});

export default planSlice.reducer;
