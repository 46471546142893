import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../Utils/Axios";
import { BASE_URL } from "../../Utils/Urls";
import { handleError } from "../../Services/ErrorHandlerServices";

export const roadmapDetailsServices = createAsyncThunk(
  "roadmapDetailsServices",
  async (payload) => {
    try {
      let url = `${BASE_URL}/static-data/roadmap-list`;
      const res = await axios.get(url, payload);
      return res.data;
    } catch (error) {
      handleError(error)
      throw error;
    }
  }
);


export const userLevelServices = createAsyncThunk(
    "userLevelServices",
    async (payload) => {
      try {
        let url = `${BASE_URL}/user/user-level-details`;
        const res = await axios.get(url);
        return res.data;
      } catch (error) {
        handleError(error)
        throw error;
      }
    }
  );


  export const classOverViewDetailsServices = createAsyncThunk(
    "classOverViewDetailsServices",
    async (payload) => {
      try {
        let url = `${BASE_URL}/user/class-overview-details`;
        const res = await axios.get(url);
        return res.data;
      } catch (error) {
        handleError(error)
        throw error;
      }
    }
  );


  export const userFeedBackReportServices = createAsyncThunk(
    "userFeedBackReportServices",
    async (payload) => {
      try {
        let url = `${BASE_URL}/feedback/user-progress-report`;
        const res = await axios.get(url);
        return res.data;
      } catch (error) {
        handleError(error)
        throw error;
      }
    }
  );


  export const userConditionServices = createAsyncThunk(
    "userConditionServices",
    async (payload) => {
      try {
        let url = `${BASE_URL}/user/user-configuration-details`;
        const res = await axios.get(url);
        return res.data;
      } catch (error) {
        handleError(error)
        throw error;
      }
    }
  );
  