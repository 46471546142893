import { toast } from "react-toastify";
import { errorTost } from "../Utils/Helper";

export const handleError = (err) => {
  try {
    if (err?.response?.data?.message) {
      errorTost(err.response.data.message);
      console.log(err);
    } else if (err.response.data.errors) {
      console.log(err);
      err.response.data.errors.map((d, i) => {
        errorTost(d.msg);
      })
    } 
    else {
      console.log(err);
    }
  } catch (error) {
    errorTost('Something went wrong')
    console.log(error);
    // errorTost()÷
  }

};
