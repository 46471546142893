import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../Utils/Axios";
import { BASE_URL } from "../../Utils/Urls";
import { handleError } from "../../Services/ErrorHandlerServices";

export const allTeacherListServices = createAsyncThunk(
  "allTeacherListServices",
  async (params) => {
    try {
      let url = `${BASE_URL}/teacher/teacher-list-user-dashboard?type=${params?.type}&itemsPerPage=${24}&pageNumber=${params?.pageNumber}&name=${params?.name}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      handleError(error);
      throw error;
    }
  }
);


export const demoTeacherListServices = createAsyncThunk(
    "demoTeacherListServices",
    async (status) => {
  
      try {
        let url = `${BASE_URL}/teacher/demo-teacher-list`;
        const res = await axios.get(url);
        return res.data;
      } catch (error) {
        handleError(error);
        throw error;
      }
    }
  );


  export const recommendedTeacherListServices = createAsyncThunk(
    "recommendedTeacherListServices",
    async (status) => {
      try {
        let url = `${BASE_URL}/teacher/recommended-teacher-list`;
        const res = await axios.get(url);
        return res.data;
      } catch (error) {
        handleError(error);
        throw error;
      }
    }
  );


  export const favroutireTeacherListServices = createAsyncThunk(
    "favroutireTeacherListServices",
    async (status) => {
      try {
        let url = `${BASE_URL}/teacher/favourite-teacher-list`;
        const res = await axios.get(url);
        return res.data;
      } catch (error) {
        handleError(error);
        throw error;
      }
    }
  );

  export const favrouiteTeacherServices = createAsyncThunk(
    "favrouiteTeacherServices",
    async (payload) => {
  
      try {
        let url = `${BASE_URL}/teacher/update-favourite-teacher`;
        const res = await axios.put(url,payload);
        return res.data;
      } catch (error) {
        handleError(error);
        throw error;
      }
    }
  );



  export const teacherOverDetailServices = createAsyncThunk(
    "teacherOverDetailServices",
    async (id) => {
      try {
        let url = `${BASE_URL}/teacher/teacher-details-overview?teacherId=${id}`;
        const res = await axios.get(url);
        return res.data;
      } catch (error) {
        handleError(error);
        throw error;
      }
    }
  );


  export const teacherAboutDetailServices = createAsyncThunk(
    "teacherAboutDetailServices",
    async (id) => {
      try {
        let url = `${BASE_URL}/teacher/teacher-about-us-details-user-dashboard?teacherId=${id}`;
        const res = await axios.get(url);
        return res.data;
      } catch (error) {
        handleError(error);
        throw error;
      }
    }
  );




  export const oneTeacherSessionListServices = createAsyncThunk(
    "oneTeacherSessionListServices",
    async (params) => {
      try {
        let url = `${BASE_URL}/teacher/one-teacher-session-list?teacherId=${params?.teacherId}&classType=${params?.classType}&dateTimestamp=${params?.timeStamp}`;
        const res = await axios.get(url);
        return res.data;
      } catch (error) {
        handleError(error);
        throw error;
      }
    }
  );



  export const oneTeacherEducationDeatilsServices = createAsyncThunk(
    "oneTeacherEducationDeatilsServices",
    async (id) => {
      try {
        let url = `${BASE_URL}/teacher/one-teacher-experience-and-education-details?teacherId=${id}`;
        const res = await axios.get(url);
        return res.data;
      } catch (error) {
        handleError(error);
        throw error;
      }
    }
  );
  