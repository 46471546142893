// Alert Types
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

// Authentication
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';
export const REGISTRATION_FAIL = 'REGISTRATION_FAIL';
export const MORE_DETAILS_SUCESS="MORE_DETAILS_SUCESS"
export const IS_AUTH="IS_AUTH"

// Theme Types
export const DARK_MODE = 'DARK_MODE';
export const LIGHT_MODE = 'LIGHT_MODE';

export const LOGIN_SUCESS = 'LOGIN_SUCESS';

export const COUNTRY_LIST="COUNTRY_LIST"