import { createSlice } from "@reduxjs/toolkit";
import { genrateDemoOrderServices, paymentSucessServices } from "../Thunk/paymentServices";

const initialState = {
  data: null,
  loading: false,
  error: null,
  slotList: [],
};

const paymentSlice = createSlice({
  name: "PAYMENT_SLICE",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(genrateDemoOrderServices.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(genrateDemoOrderServices.fulfilled, (state, action) => {
      
      return { ...state, slotList: action.payload.result, loading: false };
    });
    builder.addCase(genrateDemoOrderServices.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });


    builder.addCase(paymentSucessServices.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(paymentSucessServices.fulfilled, (state, action) => {
      
      return { ...state, slotList: action.payload.result, loading: false };
    });
    builder.addCase(paymentSucessServices.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });



    



  },
});

export default paymentSlice.reducer;
