import { createSlice } from "@reduxjs/toolkit";
import {
  allTeacherListServices,
  demoTeacherListServices,
  favroutireTeacherListServices,
  oneTeacherEducationDeatilsServices,
  oneTeacherSessionListServices,
  recommendedTeacherListServices,
  teacherAboutDetailServices,
  teacherOverDetailServices,
} from "../Thunk/teacherServices";

const initialState = {
  data: null,
  loading: false,
  error: null,
  planList: [],
  sucess: false,
  allTeacherList: [],
  demoTeacherList:[],
  recommendedTeacherList:[],
  favroutireTeacherList:[],
  teacherOverDetail:{},
  teacherAbout:{},
  oneTeacherSessionList:[],
  oneTeacherExperienceCertification:{},
  teacherCount:0
};

const teacherSlice = createSlice({
  name: "TEACHER_SLICE",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(allTeacherListServices.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(allTeacherListServices.fulfilled, (state, action) => {
      return {
        ...state,
        allTeacherList: action.payload.result,
        loading: false,
        teacherCount:action.payload.result?.teacherCount,
      };
    });
    builder.addCase(allTeacherListServices.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });

    builder.addCase(demoTeacherListServices.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(demoTeacherListServices.fulfilled, (state, action) => {
      return {
        ...state,
        demoTeacherList: action.payload.result,
        loading: false,
      };
    });
    builder.addCase(demoTeacherListServices.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });



    builder.addCase(recommendedTeacherListServices.pending, (state) => {
        return { ...state, loading: true };
      });
      builder.addCase(recommendedTeacherListServices.fulfilled, (state, action) => {
        return {
          ...state,
          demoTeacherList: action.payload.result,
          loading: false,
        };
      });
      builder.addCase(recommendedTeacherListServices.rejected, (state, action) => {
        return { ...state, loading: false, error: "Something went wrong" };
      });


      builder.addCase(favroutireTeacherListServices.pending, (state) => {
        return { ...state, loading: true };
      });
      builder.addCase(favroutireTeacherListServices.fulfilled, (state, action) => {
        return {
          ...state,
          favroutireTeacherList: action.payload.result,
          loading: false,
        };
      });
      builder.addCase(favroutireTeacherListServices.rejected, (state, action) => {
        return { ...state, loading: false, error: "Something went wrong" };
      });


      
      builder.addCase(teacherOverDetailServices.pending, (state) => {
        return { ...state, loading: true };
      });
      builder.addCase(teacherOverDetailServices.fulfilled, (state, action) => {
        return {
          ...state,
          teacherOverDetail: action.payload.result,
          loading: false,
        };
      });
      builder.addCase(teacherOverDetailServices.rejected, (state, action) => {
        return { ...state, loading: false, error: "Something went wrong" };
      });
      
    

      builder.addCase(teacherAboutDetailServices.pending, (state) => {
        return { ...state, loading: true };
      });
      builder.addCase(teacherAboutDetailServices.fulfilled, (state, action) => {
        return {
          ...state,
          teacherAbout: action.payload.result,
          loading: false,
        };
      });
      builder.addCase(teacherAboutDetailServices.rejected, (state, action) => {
        return { ...state, loading: false, error: "Something went wrong" };
      });



      builder.addCase(oneTeacherSessionListServices.pending, (state) => {
        return { ...state, loading: true };
      });
      builder.addCase(oneTeacherSessionListServices.fulfilled, (state, action) => {
        return {
          ...state,
          oneTeacherSessionList: action.payload.result,
          loading: false,
        };
      });
      builder.addCase(oneTeacherSessionListServices.rejected, (state, action) => {
        return { ...state, loading: false, error: "Something went wrong" };
      });


      builder.addCase(oneTeacherEducationDeatilsServices.pending, (state) => {
        return { ...state, loading: true };
      });
      builder.addCase(oneTeacherEducationDeatilsServices.fulfilled, (state, action) => {
        return {
          ...state,
          oneTeacherExperienceCertification: action.payload.result[0],
          loading: false,
        };
      });
      builder.addCase(oneTeacherEducationDeatilsServices.rejected, (state, action) => {
        return { ...state, loading: false, error: "Something went wrong" };
      });





      
      

      
  },
});

export default teacherSlice.reducer;
