import { createSlice } from "@reduxjs/toolkit";
import { allAvialbaleUserBookingSlot, bookDemoSlotServices, bookStudentSlot, userBookingSlot } from "../Thunk/userBookingSlot";
import { errorTost, sucessTost } from "../../Utils/Helper";

const initialState = {
  data: null,
  loading: false,
  error: null,
  slotList: [],
  cardLoading: false,
};

const myClassSlice = createSlice({
  name: "MYCLASS_SLICE",
  initialState,
  extraReducers: (builder) => {
    // Slot List
    builder.addCase(userBookingSlot.pending, (state) => {
      return { ...state, cardLoading: true ,slotList:[]};
    });
    builder.addCase(userBookingSlot.fulfilled, (state, action) => {
      
      return { ...state, slotList: action.payload.result, cardLoading: false };
    });
    builder.addCase(userBookingSlot.rejected, (state, action) => {
      return { ...state, cardLoading: false,slotList:[], error: "Something went wrong" };
    });


    builder.addCase(allAvialbaleUserBookingSlot.pending, (state) => {
      return { ...state, cardLoading: true ,slotList:[]};
    });
    builder.addCase(allAvialbaleUserBookingSlot.fulfilled, (state, action) => {

      function transformData(data) {
        return data.map(item => ({
          teacherId: item.teacherId,
          teacherName: item.teacherName,
          teacherImage: item.teacherImage,
          completedSessions: item.completedSessions,
          youtubeAccess: item.youtubeAccess,
          slots: [
            {
              sessionId: item.slotDetails.sessionId,
              slotId: item.slotDetails.slotId,
              name: item.slotDetails.name,
              status: item.slotDetails.status,
              slotType: item.slotDetails.slotType,
              startTime: item.slotDetails.startTime,
              endTime: item.slotDetails.endTime,
              order: item.slotDetails.order
            }
          ],
          conversionPercent: item.conversionPercent,
          sessionStartTimestamp: item.sessionStartTimestamp,
          averageRating: item.averageRating
        }));
      }
      
      let output = transformData(action.payload.result);
      
      return { ...state, slotList:output, cardLoading: false };
    });
    builder.addCase(allAvialbaleUserBookingSlot.rejected, (state, action) => {
      return { ...state, cardLoading: false,slotList:[], error: "Something went wrong" };
    });
    

    // Slot Book

    builder.addCase(bookStudentSlot.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(bookStudentSlot.fulfilled, (state, action) => {
      // sucessTost("Slot Book Sucessfully");
      return { ...state,  loading: false };
    });
    builder.addCase(bookStudentSlot.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });

    // demo book
    builder.addCase(bookDemoSlotServices.pending, (state) => {
      return {...state, loading: true };
    });
    builder.addCase(bookDemoSlotServices.fulfilled, (state, action) => {
      sucessTost("Demo Book Sucessfully");
      return { ...state,  loading: false };
    });
    builder.addCase(bookDemoSlotServices.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });
  },
});

export default myClassSlice.reducer;
