import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../Utils/Axios";
import { BASE_URL } from "../../Utils/Urls";
import { handleError } from "../../Services/ErrorHandlerServices";

export const userSubscriptionDetailsServices = createAsyncThunk(
  "userSubscriptionDetailsServices",
  async () => {
    try {
      let url = `${BASE_URL}/subscription/subscription-details-user-profile`;
      const res = await axios.get(url);
      console.log(res);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
);

export const userTransactionListServices = createAsyncThunk(
  "userTransactionListServices",
  async (activeNumber) => {
    try {
      // /order/order-history-user-profile?pageNumber=1&itemPerPage=5
      let url = `${BASE_URL}/order/order-history-user-profile?pageNumber=${activeNumber}&itemPerPage=${5}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      // console.log(error);
      //   console.log(error);
      handleError(error);
      throw error;
    }
  }
);

export const getUserProfileServices = createAsyncThunk(
    "getUserProfileServices",
    async (activeNumber) => {
      try {
        let url = `${BASE_URL}/user/user-profile-details`;
        const res = await axios.get(url);
        return res.data;
      } catch (error) {

        handleError(error);
        throw error;
      }
    }
  );

  export const sendOtpEmailServices = createAsyncThunk(
    "sendOtpEmailServices",
    async (payload) => {
      try {
        let url = `${BASE_URL}/user/send-email-verification-otp`;
        const res = await axios.post(url,payload);
        return res.data;
      } catch (error) {

        handleError(error);
        throw error;
      }
    }
  );


  export const verifyEmailServices = createAsyncThunk(
    "verifyEmailServices",
    async (payload) => {
      try {
        let url = `${BASE_URL}/user/verify-email-otp`;
        const res = await axios.put(url,payload);
        return res.data;
      } catch (error) {
        //   console.log(error);
        handleError(error);
        throw error;
      }
    }
  );


  export const activeSubscriptionservices = createAsyncThunk(
    "activeSubscriptionservices",
    async (payload) => {
      try {
        let url = `${BASE_URL}/subscription/activate-user-subscription`;
        const res = await axios.post(url,payload);
        return res.data;
      } catch (error) {
        //   console.log(error);
        handleError(error);
        throw error;
      }
    }
  );



export const downloadInvoiceServices = createAsyncThunk(
  "downloadInvoiceServices",
  async (orderId) => {
    try {
      // /order/order-history-user-profile?pageNumber=1&itemPerPage=5
      let url = `${BASE_URL}/order/user-invoice-details?orderId=${orderId}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      // console.log(error);
      //   console.log(error);
      handleError(error);
      throw error;
    }
  }
);


export const addtionalQuestionServices = createAsyncThunk(
  "addtionalQuestionServices",
  async (orderId) => {
    try {
      // /order/order-history-user-profile?pageNumber=1&itemPerPage=5
      let url = `${BASE_URL}/static-data/personal-details-questions`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      // console.log(error);
      //   console.log(error);
      handleError(error);
      throw error;
    }
  }
);


export const submitAddtionalInformationServices = createAsyncThunk(
  "submitAddtionalInformationServices",
  async (payload) => {
    try {
      let url = `${BASE_URL}/user/add-personal-details`;
      const res = await axios.post(url,payload);
      return res.data;
    } catch (error) {
      //   console.log(error);
      handleError(error);
      throw error;
    }
  }
);


// export const editProfileServices = createAsyncThunk(
//   "editProfileServices",
//   async (payload) => {
//     try {
//       let url = `${BASE_URL}/user/update-user-profile-details`;
//       // Config
//       const config = {
//         headers: {
//             'Content-Type': 'multipart/form-data'
//           // Authorization: `Bearer token`,
//         },
//       };
//       const res = await axios.post(url);
//       return res.data;
//     } catch (error) {
//       handleError(error);
//       throw error;
//     }
//   }
// );
