// Package Imports
import { combineReducers } from 'redux';

// Reducer Imports
import { alertState } from './AlertReducer';
import { signupState } from './AuthReducer';
import myClassSlice from '../Slice/myClassSlice';
import mySessionState from '../Slice/mySessionSlice';
import PlanState from '../Slice/planSlice'
import PaymentState from '../Slice/paymentSlice'
import dashboardState from '../Slice/DashboardSlice'
import commonState from '../Slice/CommonSlice';
import profileState from '../Slice/userProfileSlice';
import teacherState from '../Slice/TeacherSlice';
// import profileState from '../Slice/profileState';


export default combineReducers({ alertState,signupState,myClassSlice ,mySessionState,PlanState,PaymentState,dashboardState,commonState,profileState,teacherState});
