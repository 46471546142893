// import axios from "axios";
// import { BASE_URL } from "../Utils/Urls";
// import { toast } from "react-toastify";
// import { getStorageValue } from "./LocalStorageServices";
// import { handleError } from "./ErrorHandlerServices";
// import { createAsyncThunk } from "@reduxjs/toolkit";

// //#region On Submit Form Navigate Service
// export const onSubmitFormNavigateService = (isVerified, loading) => {
//     if (loading === false) {
//         switch (isVerified) {
//             case null: {
//                 return '/Register';
//             }

//             case false: {
//                 return '/VerifyOTP';
//             }
//         }
//     }
// };
// //#endregion

// //#region 
// export const countryListServices = async () => {
//     try {
//         // Url
//         const url = `${BASE_URL}/country/country-code-list`;

//         // Access Token
//         const token = localStorage.getItem('accessToken');

//         // Config
//         const config = {
//             headers: {
//                 'Content-Type': 'application/json',
//                 // Authorization: `Bearer token`,
//             },
//         };


//         // Get Result
//         let result = await axios.get(url, config);

//         // Promise Resolve
//         return result.data.result

//         // Promise Resolve
//         // return Promise.resolve();
//     } catch (err) {
//         console.log(err);
//         // Reject Promise
//         return Promise.reject(err);
//     }
// };
// //#endregion

// //#region regestrationOtp Services
// export const resgestrationServices = async (obj) => {
//     try {
//         // Url
//         const url = `${BASE_URL}/user/send-user-registration-otp`;

//         // Access Token
//         const token = localStorage.getItem('accessToken');

//         // Config
//         const config = {
//             headers: {
//                 'Content-Type': 'application/json',
//                 // Authorization: `Bearer token`,
//             },
//         };

//         // Data
//         let data = obj;

//         // Get Result
//         let result = await axios.post(url, data, config);
//         return result.data.result
//         // Promise Resolve
//         return Promise.resolve();
//     } catch (err) {
//         // Reject Promise
//         // alert(err.response.data.message)
//         // toast.error(err.response.data.message)
//         handleError(err)
//         console.log(err.response.data.message, 'eeeee');
//         return Promise.reject(err);
//     }
// };
// //#endregion

// //#region regestrationOtp Services
// export const loginServices = async (obj) => {
//     try {
//         // Url
//         const url = `${BASE_URL}/user/login-user`;

//         // Access Token

//         // Config
//         const config = {
//             headers: {
//                 'Content-Type': 'application/json',
//                 // Authorization: `Bearer token`,
//             },
//         };

//         // Data
//         let data = obj;

//         // Get Result
//         let result = await axios.post(url, data, config);
//         return result.data.result
//         // Promise Resolve
//         return Promise.resolve();
//     } catch (err) {
//         // Reject Promise
//         // alert(err.response.data.message)
//         // toast.error(err.response.data.message)
//         handleError(err)
//         console.log(err.response.data.message, 'eeeee');
//         return Promise.reject(err);
//     }
// };
// //#endregion


// //#region verifyOpt Services
// export const verifyOtpServices = async (obj) => {
//     try {
//         // Url
//         const url = `${BASE_URL}/user/verify-registration-otp`;

//         // Access Token
//         // const token = localStorage.getItem('accessToken');

//         // Config
//         const config = {
//             headers: {
//                 'Content-Type': 'application/json',
//                 // Authorization: `Bearer token`,
//             },
//         };

//         // Data
//         let data = obj;

//         // Get Result
//         let result = await axios.post(url, data, config);
//         return result.data.result
//         // Promise Resolve
//         return Promise.resolve();
//     } catch (err) {
//         // Reject Promise
//         toast.error(err.response.data.message)
//         // alert(err.response.data.message)
//         console.log(err.response.data.message, 'eeeee');
//         return Promise.reject(err);
//     }
// };
// //#endregion


// //#region addMoreDetails Services
// export const addMoreDetailServices = async (obj) => {
//     try {
//         // Url
//         const url = `${BASE_URL}/user/add-more-user-details`;

//         // Access Token
//         const token = getStorageValue('accessToken');

//         // Config
//         const config = {
//             headers: {
//                 'Content-Type': 'application/json',
//                 Authorization: `Bearer ${token}`,
//             },
//         };

//         // Data
//         console.log(obj);
//         let data = obj;

//         // Get Result
//         let result = await axios.post(url, data, config);
//         return result.data.result
//         // Promise Resolve
//         return Promise.resolve();
//     } catch (err) {
//         // Reject Promise
//         toast.error(err.response.data.message)
//         // alert(err.response.data.message)
//         console.log(err.response.data.message, 'eeeee');
//         return Promise.reject(err);
//     }
// };
// //#endregion




// //#region ForgetpasswordOtp Services
// export const forgetpasswordOtpServices = async (obj) => {
//     try {
//         // Url
//         const url = `${BASE_URL}/user/send-user-forget-password-otp`;

//         // Access Token

//         // Config
//         const config = {
//             headers: {
//                 'Content-Type': 'application/json',
//                 // Authorization: `Bearer ${token}`,
//             },
//         };

//         // Data
//         console.log(obj);
//         let data = obj;

//         // Get Result
//         let result = await axios.post(url, data, config);
//         return result.data.result
//         // Promise Resolve
//         return Promise.resolve();
//     } catch (err) {
//         // Reject Promise
//         toast.error(err.response.data.message)
//         // alert(err.response.data.message)
//         console.log(err.response.data.message, 'eeeee');
//         return Promise.reject(err);
//     }
// };
// //#endregion


// export const verifyGoogleIdServices = createAsyncThunk(
//     'verifyGoogleIdServices',
//     async (payload, thunkAPI) => {
//       try {
//         const url = `${BASE_URL}/user/google-user-registered-check?googleUID=${payload}`;
        
//         const config = {
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//         };
  
//         // Make the POST request with axios
//         const res = await axios.get(url, config);
        
//         // Return the response data
//         return res.data;
//       } catch (error) {
//         // Handle errors
//         console.error('An error occurred:', error);
//         throw error;
//       }
//     }
//   );




// export const googleLoginServices = createAsyncThunk(
//     'googleLoginServices',
//     async (payload, thunkAPI) => {
//       try {
//         const url = `${BASE_URL}/user/login-user-by-google?googleUID=${payload}`;
        
//         const config = {
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//         };
  
//         // Make the POST request with axios
//         const res = await axios.get(url, config);
        
//         // Return the response data
//         return res.data;
//       } catch (error) {
//         // Handle errors
//         console.error('An error occurred:', error);
//         throw error;
//       }
//     }
//   );

// export const googleRegestierServices = createAsyncThunk(
//     'googleRegestierServices',
//     async (payload, thunkAPI) => {
//       try {

//         const url = `${BASE_URL}/user/send-google-user-registration-otp`;
        
//         const config = {
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//         };
  
//         // Make the POST request with axios
//         const res = await axios.post(url, payload, config);
        
//         // Return the response data
//         return res?.data;
//       } catch (error) {
//         handleError(error)
//         console.error('An error occurred:', error);
   
//       }
//     }
//   );




// export const editProfileServices = createAsyncThunk(
//     'editProfileServices',
//     async (payload, thunkAPI) => {
//       try {
//         const token = getStorageValue('accessToken');

//         const url = `${BASE_URL}/user/update-user-profile-details`;
        
//         const config = {
//             headers: {
//                 'Content-Type': 'multipart/form-data',
//                 Authorization: `Bearer ${token}`,
//             },
//         };
  
//         // Make the POST request with axios
//         const res = await axios.put(url, payload, config);
        
//         // Return the response data
//         return res.data;
//       } catch (error) {
//         // Handle errors
//         console.error('An error occurred:', error);
//         throw error;
//       }
//     }
//   );


import axios from "axios";
import { BASE_URL } from "../Utils/Urls";
import { toast } from "react-toastify";
import { getStorageValue } from "./LocalStorageServices";
import { handleError } from "./ErrorHandlerServices";
import { createAsyncThunk } from "@reduxjs/toolkit";

//#region On Submit Form Navigate Service
export const onSubmitFormNavigateService = (isVerified, loading) => {
    if (loading === false) {
        switch (isVerified) {
            case null: {
                return '/Register';
            }

            case false: {
                return '/VerifyOTP';
            }
        }
    }
};
//#endregion

//#region 
export const countryListServices = async () => {
    try {
        // Url
        const url = `${BASE_URL}/country/country-code-list`;

        // Access Token
        const token = localStorage.getItem('accessToken');

        // Config
        const config = {
            headers: {
                'Content-Type': 'application/json',
                // Authorization: `Bearer token`,
            },
        };


        // Get Result
        let result = await axios.get(url, config);

        // Promise Resolve
        return result.data.result

        // Promise Resolve
        // return Promise.resolve();
    } catch (err) {
        console.log(err);
        // Reject Promise
        return Promise.reject(err);
    }
};
//#endregion

//#region regestrationOtp Services
export const resgestrationServices = async (obj) => {
    try {
        // Url
        const url = `${BASE_URL}/user/send-user-registration-otp`;

        // Access Token
        const token = localStorage.getItem('accessToken');

        // Config
        const config = {
            headers: {
                'Content-Type': 'application/json',
                // Authorization: `Bearer token`,
            },
        };

        // Data
        let data = obj;

        // Get Result
        let result = await axios.post(url, data, config);
        return result.data.result
        // Promise Resolve
        return Promise.resolve();
    } catch (err) {
        // Reject Promise
        // alert(err.response.data.message)
        // toast.error(err.response.data.message)
        handleError(err)
        console.log(err.response.data.message, 'eeeee');
        return Promise.reject(err);
    }
};
//#endregion

//#region regestrationOtp Services
export const loginServices = async (obj) => {
    try {
        // Url
        const url = `${BASE_URL}/user/login-user`;

        // Access Token

        // Config
        const config = {
            headers: {
                'Content-Type': 'application/json',
                // Authorization: `Bearer token`,
            },
        };

        // Data
        let data = obj;

        // Get Result
        let result = await axios.post(url, data, config);
        return result.data.result
        // Promise Resolve
        return Promise.resolve();
    } catch (err) {
        // Reject Promise
        // alert(err.response.data.message)
        // toast.error(err.response.data.message)
        handleError(err)
        console.log(err.response.data.message, 'eeeee');
        return Promise.reject(err);
    }
};
//#endregion


//#region verifyOpt Services
export const verifyOtpServices = async (obj) => {
    try {
        // Url
        const url = `${BASE_URL}/user/verify-registration-otp`;

        // Access Token
        // const token = localStorage.getItem('accessToken');

        // Config
        const config = {
            headers: {
                'Content-Type': 'application/json',
                // Authorization: `Bearer token`,
            },
        };

        // Data
        let data = obj;

        // Get Result
        let result = await axios.post(url, data, config);
        return result.data.result
        // Promise Resolve
        return Promise.resolve();
    } catch (err) {
        // Reject Promise
        toast.error(err.response.data.message)
        // alert(err.response.data.message)
        console.log(err.response.data.message, 'eeeee');
        return Promise.reject(err);
    }
};
//#endregion


//#region addMoreDetails Services
export const addMoreDetailServices = async (obj) => {
    try {
        // Url
        const url = `${BASE_URL}/user/add-more-user-details`;

        // Access Token
        const token = getStorageValue('accessToken');

        // Config
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };

        // Data
        console.log(obj);
        let data = obj;

        // Get Result
        let result = await axios.post(url, data, config);
        return result.data.result
        // Promise Resolve
        return Promise.resolve();
    } catch (err) {
        // Reject Promise
        toast.error(err.response.data.message)
        // alert(err.response.data.message)
        console.log(err.response.data.message, 'eeeee');
        return Promise.reject(err);
    }
};
//#endregion




//#region ForgetpasswordOtp Services
export const forgetpasswordOtpServices = async (obj) => {
    try {
        // Url
        const url = `${BASE_URL}/user/send-user-forget-password-otp`;

        // Access Token

        // Config
        const config = {
            headers: {
                'Content-Type': 'application/json',
                // Authorization: `Bearer ${token}`,
            },
        };

        // Data
        console.log(obj);
        let data = obj;

        // Get Result
        let result = await axios.post(url, data, config);
        return result.data.result
        // Promise Resolve
        return Promise.resolve();
    } catch (err) {
        // Reject Promise
        toast.error(err.response.data.message)
        // alert(err.response.data.message)
        console.log(err.response.data.message, 'eeeee');
        return Promise.reject(err);
    }
};
//#endregion



export const verifyGoogleIdServices = createAsyncThunk(
    'verifyGoogleIdServices',
    async (payload, thunkAPI) => {
      try {
        const url = `${BASE_URL}/user/google-user-registered-check?googleUID=${payload}`;
        
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
  
        // Make the POST request with axios
        const res = await axios.get(url, config);
        
        // Return the response data
        return res.data;
      } catch (error) {
        // Handle errors
        console.error('An error occurred:', error);
        throw error;
      }
    }
  );




export const googleLoginServices = createAsyncThunk(
    'googleLoginServices',
    async (payload, thunkAPI) => {
      try {
        const url = `${BASE_URL}/user/login-user-by-google?googleUID=${payload}`;
        
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
  
        // Make the POST request with axios
        const res = await axios.get(url, config);
        
        // Return the response data
        return res.data;
      } catch (error) {
        // Handle errors
        console.error('An error occurred:', error);
        throw error;
      }
    }
  );

export const googleRegestierServices = createAsyncThunk(
    'googleRegestierServices',
    async (payload, thunkAPI) => {
      try {

        const url = `${BASE_URL}/user/send-google-user-registration-otp`;
        
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
  
        // Make the POST request with axios
        const res = await axios.post(url, payload, config);
        
        // Return the response data
        return res?.data;
      } catch (error) {
        return error
        // handleError(error)
        // console.error('An error occurred:', error);
   
      }
    }
  );




export const editProfileServices = createAsyncThunk(
    'editProfileServices',
    async (payload, thunkAPI) => {
      try {
        const token = getStorageValue('accessToken');

        const url = `${BASE_URL}/user/update-user-profile-details`;
        
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`,
            },
        };
  
        // Make the POST request with axios
        const res = await axios.put(url, payload, config);
        
        // Return the response data
        return res.data;
      } catch (error) {
        // Handle errors
        console.error('An error occurred:', error);
        throw error;
      }
    }
  );



//   Forget password 

// 1 get otp 

export const sendForgetPasswordOtpServices = createAsyncThunk(
    'sendForgetPasswordOtpServices',
    async (payload, thunkAPI) => {
      try {

        const url = `${BASE_URL}/user/send-forget-password-otp`;
        
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
  
        // Make the POST request with axios
        const res = await axios.post(url, payload, config);
        
        // Return the response data
        return res?.data;
      } catch (error) {
        handleError(error)
        console.error('An error occurred:', error);
   
      }
    }
  );

// 2 verify Otp
  export const verifiyForgetPasswordOtpServices = createAsyncThunk(
    'verifiyForgetPasswordOtpServices',
    async (payload, thunkAPI) => {
      try {

        const url = `${BASE_URL}/user/verify-forget-password-otp`;
        
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
  
        // Make the POST request with axios
        const res = await axios.post(url, payload, config);
        
        // Return the response data
        return res?.data;
      } catch (error) {
        handleError(error)
        console.error('An error occurred:', error);
   
      }
    }
  );

// 3 reset password 
//   export const resetForgetPasswordOtpServices = createAsyncThunk(
//     'resetForgetPasswordOtpServices',
//     async (payload, thunkAPI) => {
//       try {
//         const url = `${BASE_URL}/user/create-new-user-password`;
//         const token = getStorageValue('forgetToken');
//         const config = {
//             headers: {
//                 'Content-Type': 'application/json',
//                 Authorization: `Bearer ${token}`,
//             },
//         };
  
//         // Make the POST request with axios
//         const res = await axios.post(url, payload, config);
        
//         // Return the response data
//         return res?.data;
//       } catch (error) {
//         handleError(error)
//         console.error('An error occurred:', error);
   
//       }
//     }
//   );


export const resetForgetPasswordOtpServices = createAsyncThunk(
    'resetForgetPasswordOtpServices',
    async (payload, thunkAPI) => {
        try {
            const url = `${BASE_URL}/user/create-new-user-password`;
            const token = getStorageValue('forgetToken');
            const config = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(payload),
            };

            // Make the POST request with fetch
            const res = await fetch(url, config);
            
            // Check if the response is successful
            if (!res.ok) {
                throw new Error('Failed to reset password');
            }

            // Parse the response data
            const data = await res.json();

            // Return the response data
            return data;
        } catch (error) {
            console.log(error);
            // Dispatch an action to handle the error
            // thunkAPI.rejectWithValue(error.message);
        }
    }
);





export const mailClouderServices = createAsyncThunk(
    'mailClouderServices',
    async (payload, thunkAPI) => {
        try {
            const url = `https://cloudapi.mailercloud.com/v1/contacts`;
            const config = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `CUFXK-5d7edd96a30ab0eab310464b7ff9952c-8764dd71ba5df24e5ac52a1b6b7d4480`,
                },
                body: JSON.stringify(payload),
            };

            // Make the POST request with fetch
            const res = await fetch(url, config);
            
            // Check if the response is successful
            if (!res.ok) {
                throw new Error('Failed to reset password');
            }

            // Parse the response data
            const data = await res.json();

            // Return the response data
            return data;
        } catch (error) {
            console.log(error);
            // Dispatch an action to handle the error
            // thunkAPI.rejectWithValue(error.message);
        }
    }
);



