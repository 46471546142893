// Types Imports
import { COUNTRY_LIST, IS_AUTH, MORE_DETAILS_SUCESS, REGISTRATION_FAIL, REGISTRATION_SUCCESS } from '../Types';




const initialStates = {
	data: null,
	loading: false,
	error: null,
	countryList: [],
	stateList: [],
	isRegestrationSucess: false,
	addMoreDetailsSucess: false,
	isAuth: false
};

export const signupState = (state = initialStates, action) => {
	let { type, payload } = action
	switch (type) {
		case COUNTRY_LIST:
			return {
				...state,
				loading: false,
				countryList: payload.countryList
			};
		case REGISTRATION_SUCCESS:
			return {
				...state,
				loading: false,
				isRegestrationSucess: true
			};
		case MORE_DETAILS_SUCESS:
			return {
				...state,
				loading: true,
				addMoreDetailsSucess: true
			};
		case IS_AUTH:
			return {
				...state,
				loading: true,
				isAuth: payload
			};

		default:
			return state;
	}
};
